<template>
  <div class="register-component">
    <v-container id="login" class="fill-height justify-center" tag="section">
      <v-row class="center mt-6">
        <v-col lg="11" xl="7" class="padding margin-auto">
          <v-card class="elevation-4">
            <div class="myrow">
              <v-col lg="5">
                <div class="pa-2 pa-sm-6">
                  <img class="img" src="../../assets/MedDrive-logo.jpg" alt />

                  <h2
                    class="
                      font-weight-bold
                      mt-4
                      blue-grey--text
                      text--darken-2
                      title
                    "
                  >
                    Sign Up
                  </h2>
                  <h6 class="subtitle-1" style="font-weight: bold">
                    Have an account?
                    <router-link :to="{ name: 'Login' }">Sign in</router-link>
                  </h6>

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="email"
                      hide-details="auto"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      outlined
                      class="mt-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :counter="10"
                      hide-details="auto"
                      :rules="passwordRules"
                      label="Password"
                      required
                      outlined
                      class="mt-4"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                    ></v-text-field>
                    <!-- <p>Choose account type</p> -->

                    <!-- <v-btn-toggle v-model="accountType">
                    <v-btn>Driver</v-btn>

                    <v-btn>Business</v-btn>
                  </v-btn-toggle> -->

                    <div class="d-block d-sm-flex align-center mb-3">
                      <v-checkbox
                        v-model="agreeOnPrivacy"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'You must agree to continue!']"
                        label="I agree to the terms and privacy"
                        required
                      ></v-checkbox>
                    </div>
                    <v-btn
                      :disabled="!valid"
                      color="info"
                      block
                      class="mr-4"
                      submit
                      @click="submit"
                      >Sign Up</v-btn
                    >
                  </v-form>
                </div>
              </v-col>
              <v-col class="row-img__conteiner info d-none d-md-flex align-center justify-center">
                <img class="row-img__img" src="@/assets/images/sign-up.png" alt="" />
                <!-- <div class="d-none d-sm-block">
                  <div class="d-flex align-center pa-10">
                    <div>
                      <h2 class="display-1 white--text font-weight-medium">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor
                      </h2>
                      <h6
                        class="
                          subtitle-1
                          mt-4
                          white--text
                          op-5
                          font-weight-regular
                        "
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </h6>
                      <v-btn
                        class="mt-4 text-capitalize"
                        x-large
                        outlined
                        color="white"
                        >Learn More</v-btn
                      >
                    </div>
                  </div>
                </div> -->
              </v-col>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ notification }}
        <template v-slot:action="{ attrs }">
          <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BoxedLogin",
  data: () => ({
    valid: true,
    registerType: 0,
    accountType: 0,
    agreeOnPrivacy: false,
    show1: false,
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    //
    notification: "",
    snackbar: false,
  }),
  computed: {
    ...mapGetters(["isAdmin", "isClientAdmin", "isClientUser", "isDriver"]),
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.$store
          .dispatch("auth/register", {
            username: this.email,
            password: this.password,
          })
          .then((response) => {
            this.notification = "Successfull";
            this.snackbar = true;
            // this.$router.push({ name: "HomePage", hash: "#home" });
            this.$router.push({ name: "Dashboard" });
          })
          .catch((err) => {
            console.error(err);
            this.notification = "The user with this email already exists";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-component {
  padding: 65px 0 100px 0;

  @media screen and (max-width: 940px) {
    padding: 30px 0 65px 0;
  }
}

.row-img__conteiner {
  padding: 0;
}

.row-img__img {
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  max-width: 140px;

  @media screen and (max-height: 850px) {
    display: none;
  }
}

.myrow {
  display: flex;
  flex-direction: row;
}

.main-container {
  @media screen and (max-width: 959px) {
    flex-direction: column !important;
  }
}

.mycontainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.col {
  @media screen and (max-width: 1264px) {
    padding-top: 0 !important;
  }
}

.upload-btns {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  flex-direction: column;
}

.myinput {
  margin-right: 10px;
}

.myrow {
  display: flex;
}

.margin-auto {
  margin: auto;
}

.padding {
  padding: 0 55px;

  @media screen and (max-width: 485px) {
    padding: 0 15px;
  }
}

.title {
  @media screen and (max-height: 850px) {
    margin-top: 0 !important;
  }
}
</style>
